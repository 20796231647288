import { isEmpty } from 'lodash';
export default function CategoryTag({
    tags,
    parentSetState,
    className = '',
    itemClassName = '',
    ulClassName = '',
}) {
    return (
        !isEmpty(tags) && (
            <div className={'categoryTag ' + className}>
                <ul
                    className={ulClassName}
                    style={{
                        whiteSpace: 'nowrap',
                        width: '100%',
                        display: 'block',
                        overflowX: 'scroll',
                    }}
                >
                    {!isEmpty(tags) &&
                        tags.map((item) => {
                            return (
                                <li
                                    key={item.id}
                                    style={{ display: 'inline-block' }}
                                    className={itemClassName}
                                >
                                    <span className="tag">
                                        {item.text}
                                        <span
                                            className="item-close"
                                            onClick={() => {
                                                parentSetState({
                                                    filters: tags.filter(
                                                        (f) => f.id !== item.id,
                                                    ),
                                                });
                                            }}
                                        ></span>
                                    </span>
                                </li>
                            );
                        })}
                </ul>
            </div>
        )
    );
}
