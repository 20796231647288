/* eslint-disable @next/next/no-img-element */
import Filter from 'components/cong-dong/story/filter';
import http from 'utilities/http';
import CategoryTag from 'components/cong-dong/story/categoryTag';
import Post from 'components/cong-dong/imageStore';
import PostBaiViet from 'components/cong-dong/home-story';
import { isEmpty, has } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import Paginate from 'components/home/paginate';
import { toast } from 'react-toastify';
import { getCookie } from 'framework/libs/cookie';
import { TOKEN_CONGDONG } from 'framework/store/constants';
import Slider from 'react-slick';
import { formatSku, numberWithDot } from 'utilities/helper';
import Head from 'next/head';
import SliderAd from 'components/chuyen-gia/slider';
import { TermSchema } from '../schemas/TermSchema';
import ButtonModalDonTuVan from 'components/don-tu-van/buttonModalDonTuVan';
import { useCart } from 'framework/store';
import { useRouter } from 'next/router';
import { getFilters } from 'pages';
import { Home2 } from 'iconsax-react';
import { BtnDangBai } from 'components/home/header';

const countPostOverSlider = 6;
const perPage = 15;
const formatParams = (filter) => {
    let query = filter.map((item) => {
        return `terms[]=${item.id}`;
    });
    return query.join('&');
};
export default function Index({ home, type, termId, slug, category }) {
    const [stateStore, actions] = useCart();
    const router = useRouter();

    const countPostOverSlider = slug == 'kho-360' ? 8 : 6;
    const perPage = slug == 'kho-360' ? 16 : 15;
    const [state, _setState] = useState({
        paginate: [perPage, home?.meta?.pagination?.total || 0],
        contents: home?.data || [],
        filters: [],
        showFilter: '',
        term: {},
    });
    const filterRef = useRef();
    const fetchData = async (pageNumber) => {
        let url = `community/${type}/term/${termId}?page=${pageNumber}&per_page=${perPage}`;
        if (!isEmpty(state.filters)) {
            url += `&${formatParams(state.filters)}`;
        }
        const response = await http(
            url,
            {},
            { token: 'Bearer ' + getCookie(TOKEN_CONGDONG) },
            'v2',
        );
        if (response.code === 404) {
            toast.error(response.message);
            return;
        }
        setState({
            term: response.current_term?.data,
            contents: response.data,
            paginate: [perPage, response.meta?.pagination?.total || 0],
        });
    };
    useEffect(() => {
        if (!isEmpty(state.filters) && state.filters[0].isChuDe) {
            router.push(state.filters[0].id);
            return;
        }
        fetchData(1);
    }, [state.filters]);
    const setState = (_state = {}) => {
        _setState((state) => ({
            ...state,
            ..._state,
        }));
    };
    const settingsBanner = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        // eslint-disable-next-line react/display-name
        appendDots: (dots) => <ul>{dots}</ul>,
    };
    const schema = new TermSchema(state.term);
    const sTerm = schema.init();

    const [optionsFilter, setOptionsFilter] = useState([]);
    useEffect(() => {
        if (isEmpty(stateStore.communityCategories)) return;
        const fetchFilter = async () => {
            const res = await http(
                `community/article/filter/${category?.id}?per_page=20`,
                {},
                {},
                'v2',
            );
            if (res?.code == 200) {
                const options = getFilters(
                    res?.data || [],
                    stateStore.communityCategories,
                );
                setOptionsFilter(options);
            }
        };
        fetchFilter();
    }, [stateStore.communityCategories]);
    return (
        <>
            <Head>
                <title>{category.name}</title>
                <meta property="og:title" content={category.name} key="title" />
                <link
                    rel="canonical"
                    key="canonical"
                    href={`https://happynest.vn/${category.slug}`}
                />
                <meta
                    property="og:url"
                    content={`https://happynest.vn/${category.slug}`}
                />
                {!isEmpty(state.term) &&
                    typeof state.term.seo != 'undefined' && (
                    <meta
                        property="og:description"
                        content={state.term.seo.data.description}
                        key="og:description"
                    />
                ) && (
                    <meta
                        property="description"
                        content={state.term.seo.data.description}
                        key="description"
                    />
                )}
                {!isEmpty(state.contents) && (
                    <meta
                        property="og:image"
                        content={state.contents[0].storage_thumbnail_url_large}
                        key="og:image"
                    />
                )}
                <meta
                    property="og:image:width"
                    content="600"
                    key="og:image:width"
                />
                <meta
                    property="og:image:height"
                    content="400"
                    key="og:image:height"
                />
                {sTerm.map((item, index) => {
                    return (
                        <script
                            type="application/ld+json"
                            key={index}
                            dangerouslySetInnerHTML={{
                                __html: JSON.stringify(item),
                            }}
                        ></script>
                    );
                })}
            </Head>
            <section className="main main--photoStore">
                <div className="wrap anchor-link" id="danh-sach">
                    {!isEmpty(state.showFilter) && (
                        <div
                            className="backdrop backdrop-filter"
                            onClick={() => {
                                setState({ showFilter: '' });
                                has(filterRef, ['current', 'cleanSelected']) &&
                                    filterRef.current.cleanSelected();
                            }}
                        ></div>
                    )}
                    <div className="position-relative">
                        <Filter
                            ref={filterRef}
                            options={optionsFilter}
                            parentState={state}
                            filters={state.filters}
                            parentSetState={setState}
                            style={{ marginRight: '30px' }}
                        />
                        <BtnDangBai />
                    </div>
                    <CategoryTag
                        tags={state.filters}
                        parentSetState={setState}
                    />
                    <p className="post">
                        <span>{numberWithDot(state.paginate[1])}</span> bài đăng
                    </p>
                    {state.contents?.length > 0 &&
                        (type === 'album' ? (
                            <Post
                                slug={slug}
                                title=""
                                item={state.contents.filter(
                                    (f, k) => k < countPostOverSlider,
                                )}
                            />
                        ) : (
                            <PostBaiViet
                                slug={slug}
                                title=""
                                item={state.contents.filter(
                                    (f, k) => k < countPostOverSlider,
                                )}
                                {...(slug == 'kho-360' ? { perRow: 4 } : {})}
                            />
                        ))}
                    <SliderAd
                        positionDesktop="2"
                        pageDesktop="60"
                        positionMobile="6"
                        pageMobile="60"
                    />
                    {state.contents?.length > countPostOverSlider &&
                        (type === 'album' ? (
                            <Post
                                slug={slug}
                                title=""
                                item={state.contents.filter(
                                    (f, k) => k >= countPostOverSlider,
                                )}
                            />
                        ) : (
                            <PostBaiViet
                                slug={slug}
                                title=""
                                item={state.contents.filter(
                                    (f, k) => k >= countPostOverSlider,
                                )}
                                {...(slug == 'kho-360' ? { perRow: 4 } : {})}
                            />
                        ))}
                    {state?.paginate[1] > state.paginate[0] && (
                        <Paginate
                            paginate={state.paginate}
                            fetchData={fetchData}
                            scrollToEl="#danh-sach"
                        />
                    )}
                </div>
            </section>
        </>
    );
}
export const getStaticPaths = async () => {
    const res = await http(`community/categories`, {}, {}, 'v2');
    const paths = (res?.data || [])
        .filter((x) => x.slug != 'happynest-story' && x.slug != 'lam-nha')
        .map((x) => {
            return {
                params: {
                    slug: x.slug == 'kho-anh' ? 'album' : x.slug,
                },
            };
        });
    return {
        paths: paths,
        fallback: 'blocking',
    };
};

const contentType = (slug) => {
    let contentType = 'article';
    switch (slug) {
    case 'album':
        contentType = 'album';
        return contentType;
    case 'thao-luan':
        contentType = 'thread';
        return contentType;
    case 'chuyen-gia':
        contentType = 'professional';
        return contentType;
    default:
        return contentType;
    }
};
export const getStaticProps = async ({ params }) => {
    const slug = params.slug;
    const type = contentType(slug);
    const response = await http(
        `community/categories?per_page=50`,
        {},
        { token: 'default' },
        'v2',
    );
    let category = null;
    if (!isEmpty(response?.data)) {
        let slugCompare = slug;
        if (slug == 'album') slugCompare = 'kho-anh';

        category = response.data.find((item) => item.slug === slugCompare);
    }
    return {
        props: {
            key: slug,
            type: type,
            termId: category?.id || 0,
            slug,
            category: category || {},
        },
        revalidate: 30,
    };
};
