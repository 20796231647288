import { BaseSchema } from "./BaseSchema";
import { isEmpty } from "lodash";
import { formatSku, getUrlName } from "../utilities/helper";

export class TermSchema extends BaseSchema {
    link = "";
    constructor(entry) {
        entry = entry || {};
        super();
        this.link = `https://happynest.vn/${formatSku(entry.slug)}`;
        const webPage = this.webPage(entry);
        this.schema.push(webPage);
    }

    webPage(entry) {
        entry = entry || {};
        const data = {
            "@context": "https://schema.org/", "@type": "WebPage",
        };
        if (typeof entry.seo != "undefined") {
            data["name"] = isEmpty(entry.seo.data.title) ? entry.name : entry.seo.data.title;
            data["description"] = isEmpty(entry.seo.data.description) ? entry.name : entry.seo.data.description;
            data['url'] = this.link;
            data["isPartOf"] = {
                "@type": "WebSite",
                "url": "https://happynest.vn/",
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": { "@type": "EntryPoint", "urlTemplate": "https://happynest.vn/tim-kiem?keyword={keyword}" },
                    "query-input": {
                        "@type": "PropertyValueSpecification",
                        "valueRequired": "http://schema.org/True",
                        "valueName": "keyword"
                    }
                }
            };
            data["primaryImageOfPage"] = {
                "@type": "ImageObject",
                "@id": entry.thumbnail + '/#imageObject',
                "url": entry.thumbnail,
                "width": "800",
                "height": "400",
                "caption": entry.name,
                "inLanguage": "vi"
            };
            data["inLanguage"] = "vi";
        }
        return data;
    }
}