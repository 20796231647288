import {useEffect, useState} from "react";
import Image from "next/image";
import {useRouter} from 'next/router';

// paginate: [size, total]
// function fetchData(pagenumber)
export default function Paginate({paginate, fetchData, scrollToEl, reset, haveInit, isSiteMini = false}) {
    const router = useRouter();
    const [pageInit, setPageInit] = useState(true);
    let [page, setPage] = useState(1);
    let size = paginate[0] < paginate[1] ? paginate[0] : paginate[1];
    let total = paginate[1];
    const classSiteMini = isSiteMini?'page-sitemini':"";
    let totalPage = Math.ceil(total / size);
    let paginateList = [];
    useEffect(() => {
        // if (page !== 1) {
        if (haveInit && pageInit) return;
        fetchData(page);
        // }
    }, [page]);

    useEffect(() => {
        setPageInit(false);
    }, []);

    useEffect(() => {
        if(!reset) return;
        setPage(1);
    }, [reset]);

    if (totalPage <= 5) {
        new Array(totalPage).fill(0).forEach((item, index) => paginateList.push(index + 1));
    } else {
        switch (page) {
        case 1 :
        case 2:
            new Array(5).fill(0).forEach((item, index) => paginateList.push(index + 1));
            break;
        case totalPage - 2:
            paginateList = [page - 2, page - 1, page, page + 1, page + 2];
            break;
        case totalPage - 1:
            paginateList = [page - 3, page - 2, page - 1, page, page + 1];
            break;
        case totalPage:
            paginateList = [page - 4, page - 3, page - 2, page - 1, page];
            break;
        default:
            paginateList = [page - 1, page, page + 1, page + 2, page + 3];
        }
    }

    function genFist() {
        if (totalPage > 5 & page > 1) {
            return (<>
                <li><a onClick={() => {
                    changeCurrentPage("first");

                }}><Image width={12} height={12} src="/images/icon-paginationAll.svg" alt=""/></a></li>
                <li><a onClick={() => {
                    changeCurrentPage("previous");
                }}><Image width={12} height={12} src="/images/icon-pagination.svg" alt=""/></a></li>
            </>);
        }
    }

    function genLast() {
        if (totalPage > 5 & page < totalPage) {
            return (<>
                <li><a onClick={() => {
                    changeCurrentPage("next");
                }}><Image width={12} height={12} src="/images/icon-pagination01.svg" alt=""/></a></li>
                <li><a onClick={() => {
                    changeCurrentPage("last");
                }}><Image width={12} height={12} src="/images/icon-paginationAll01.svg" alt=""/></a></li>
            </>);
        }
    }

    function active(val) {
        if (val == page) {
            return "active";
        }
    }

    const changeCurrentPage = (item) => {
        if (scrollToEl) router.push(scrollToEl);

        switch (item) {
        case "first" :
            setPage(1);
            return 1;
        case "previous" :
            setPage(page - 1);
            return page - 1;
        case "next" :
            setPage(page + 1);
            return page + 1;
        case "last" :
            setPage(totalPage);
            return totalPage;
        default :
            setPage(item);
            return item;
        }
    };

    const genPagination = paginateList.map((item, key) => {
        return <li key={key}><a className={classSiteMini+' '+active(item)} onClick={async () => {
            const pageNumber = changeCurrentPage(item);
        }}>{item}</a></li>;
    });

    if (totalPage < 2) return <></>;

    return (
        <div className="pagination">
            <ul>
                {genFist()}
                {genPagination}
                {genLast()}
            </ul>
            <p className="pagination-note">Hiển thị {page === totalPage ? total - (page - 1) * size : size} kết quả trên
                tổng {total}</p>
        </div>
    );
}

